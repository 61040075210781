<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <IconClassBar
          title="Zwischenbericht"
          :marks="true"
          :comments="true"
          :presence="true"
          current="quarter-marks"
          v-on:edit-mode="editMode=true"
        />
        <v-card-text>
          <v-row
            align="start"
            class="ml-2"
          >
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-select
                :items="classes"
                filled
                label="Klasse"
                dense
                outlined
                color="success"
                class="mt-3"
              ></v-select>
            </v-col>
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-select
                :items="subjects"
                filled
                label="Fach"
                dense
                outlined
                color="success"
                class="mt-3"
              ></v-select>
            </v-col>
            <v-col
              class="d-flex"
              cols="11"
              sm="4"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="students"
            item-key="name"
            class="elevation-1 table-one"
            multi-sort
            dense
          >
            <template v-slot:header.marks[0]="{ header }">
              {{ header.text }}<br>
              Datum: {{ header.date }}
            </template>
            <template v-slot:header.marks[1]="{ header }">
              {{ header.text }}<br>
              Datum: {{ header.date }}
            </template>
            <template v-slot:item.name="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="item.img"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.marks[0]="{item}">
              <p class="ma-0 font-weight-medium" v-if="!editMode">
                {{ item.marks[0] }}
              </p>
              <v-row no-gutters v-else class="my-1">
                <v-col md="3">
                  <v-text-field
                    hide-details="auto"
                    outlined
                    dense
                    v-model="item.marks[0]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.marks[1]="{item}">
              <p class="ma-0 font-weight-medium">
                {{ item.marks[1] }}
              </p>
            </template>
            <template v-slot:footer>
              <div style="position: absolute" class="pl-2 pt-2">
                <v-chip
                  class="ma-2"
                  label
                  color="teal"
                  text-color="white"
                >
                  Durchschnitt: 4.0
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <div class="d-flex flex-wrap mt-4" v-if="editMode">
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="editMode=false"
            >
              Speichern
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="blue"
              small
            >
              Publizieren
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="editMode=false"
            >
              Abbrechen
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue';
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'QuarterMarksPMA',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        editMode: false,
        search: '',
        selected: [],
        headers: [
          {
            text: 'Lernende',
            align: 'start',
            sortable: false,
            value: 'name',
          },   
          { 
            text: 'Quartal', 
            value: 'marks[0]',
            date: '01.08.2021'
          },
          { 
            text: 'Prognose', 
            value: 'marks[1]',
            date: '10.08.2021'
          },
        ],
        students: [
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe two',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe two',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend two',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut two',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa two',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe three',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend three',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut three',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/007-woman-2.svg'),
            name: 'Medusa three',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/001-man.svg'),
            name: 'Jhon Doe four',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/002-woman.svg'),
            name: 'Elizabeth Doe four',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/003-man-1.svg'),
            name: 'Jhon Legend four',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
          {
            img: require('@/assets/images/avatars/004-bald.svg'),
            name: 'Juggernaut four',
            comment: 'Kommentar 1',
            action: 'add',
            marks: [4, 5]
          },
        ],
        subjects: [
          'Administration',
          'Wirtschaft und Gesellschaft',
        ],
        classes: [
          'Bürofachdiplom',
          'Handelsdiplom',
        ],
        exams: [
          {
            name: 'Mündliche Prüfung',
            weight: 0.5,
            mark: 6.0,
            date: '01.06.2021',
          },
          {
            name: 'Vortrag',
            weight: 0.3,
            mark: 5.5,
            date: '02.06.2021',
          },
          {
            name: 'Projekt',
            weight: 0.2,
            mark: 5.5,
            date: '03.06.2021',
          },
        ],
      }
    },
  }
</script>
